import WebIM from "./Easemob-my";
//import WebIM from "./Easemob";
//import WebIM from "easemob-websdk/circleSdk/Easemob-chat";

WebIM.conn = new WebIM.connection({
//  Pass your appKey here.
  appKey: "easemob-demo#chatdemo",
});

export default WebIM;
