var WebIM = {};
var WebIM_ws;

function checkEnvironment() {
    const hostname = window.location.hostname;
    const localRegex = /^192\.168\.\d{1,3}\.\d{1,3}$/;

    if (hostname === 'localhost' || hostname === '127.0.0.1' || localRegex.test(hostname)) {
        console.log('页面在本地运行');
        return 'local';
    } else {
        console.log('页面在互联网上运行');
        return 'online';
    }
}


var socketUrl = "ws://127.0.0.1:8001/IM服务器/hx";
var apiUrl = "http://127.0.0.1:8001/api.js";

if(checkEnvironment() == 'online'){
	socketUrl = "wss://haolao.top/hx";
	apiUrl = "https://haolao.top/api.js";
}

(function (){var exports = {};


exports.connection = function(参数){ 
	console.log("连接" + JSON.stringify(参数));
	var 保存的令牌 = "";
	var 绑定 = {}//动态绑定的指令
	var 绑定的时间 = {};
	function 增加绑定(方法,回调){
		//使用队列的方法来解决回调和网络回应错位的情况,但在复杂情况下,仍会出现错位.
		//因为一开始没想到会出这样的问题,所以用了这个虽然不能完全解决问题但是改造比较简单的办法.
		//如果要完全解决问题,需要每个网络请求都生成一个唯一id,然后根据id来回调.
		var time = +new Date();
		if(绑定[方法]){
			var ot= 绑定的时间[方法];
			if(time - ot > 2000){//将超过一定时间没有返回的回调丢弃
				绑定[方法].length = 0;
			}
			绑定[方法].push(回调);
		}else{
			绑定[方法] = [回调];
		}
		绑定的时间[方法] = time;
	}
	var 接收 = {//固定的指令
		登录成功:function(令牌){
			if(令牌){
				if(绑定["接收令牌"].length){
					保存的令牌 = 令牌.accessToken;
					绑定["接收令牌"].shift()(令牌)
				}else 保存的令牌 = "";
			}
			while(发送队列.length){
				var msg = 发送队列.shift();
				发送(msg);
			}
		}
		,事件:function(类型,方法,参数){
			绑定[类型][方法](...参数);
		}
		,pong:function(){}
	}
	var ping时间 = 10000;
	function 连接服务器(){
		WebIM_ws = new WebSocket(socketUrl);
		WebIM_ws.binaryType = "blob";
		WebIM_ws.onmessage = function (event) {
			var obj = JSON.parse(event.data)
			var 指令 = obj.shift();
			if(接收[指令]){
				接收[指令](...obj);
			}else{
				if(绑定[指令].length)
					绑定[指令].shift()(...obj);
				else
					console.log("接收到未绑定指令:" + 指令)
			}
		}
		WebIM_ws.onclose = function(){
			console.log("网络连接已关闭");
			if(ping时间 != 1000)连接服务器();
			ping时间 = 1000;
		}
		WebIM_ws.onopen = function () {
			发送(["token",保存的令牌])
			ping时间 = 10000;
			while(发送队列.length){
				var msg = 发送队列.shift();
				发送(msg);
			}
		}
	}
	连接服务器();
	function ping程序() {
		if(WebIM_ws && WebIM_ws.readyState == 1){
			发送(["ping"]);
		}else{
			if(WebIM_ws.readyState != 0)连接服务器();
		}
		setTimeout(ping程序, ping时间);
	}
	setTimeout(ping程序, 1000);

	var 发送队列 = [];

	var 发送 = function(msg){
		if(WebIM_ws && WebIM_ws.readyState == 1){
			WebIM_ws.send(JSON.stringify(msg));
		}else{
			发送队列.push(msg);
			if(WebIM_ws.readyState != 0)连接服务器();
		}
	}


	var obj = {
		addEventHandler : function(msg,fun){
				console.log("绑定" + msg);
				console.info(fun)
				if(msg === "login"){
					接收.进入连接 = fun.onConnected
					接收.退出登录 = fun.onDisconnected
					return;
				}
				for(var i in fun){
					if(typeof(fun[i]) == "function")接收[i] = fun[i];
				}
				
				//Call getConversationlist
				//Call getContacts
		}
		,open : function(用户){
				console.log("连接open")
				while(!用户.accessToken && !(WebIM_ws && WebIM_ws.readyState == 1)){
					return new Promise((function (r, n) {
						n("服务器还未连接成功，请稍后再试！");
					}))
				}
				发送(["登录",[用户.user,用户.pwd,用户.accessToken]]);
				return new Promise((function (r, n) {
						console.log("Promise连接open")
						增加绑定("接收令牌" , r);
						增加绑定("登录失败" , n);
					}))
		}
		,close : function(){
				保存的令牌 = "";
				console.log("连接close")
				发送(["close"])
				return true;
		}
		,send : function(参数){
			var t = "";
			for(var i=0;i<arguments.length;i++)
			t += ("<参数"+ i + ">:"+JSON.stringify(arguments[i]));
			console.log("conn:send:" + t);
	
			发送(["发送",参数]);
			return new Promise(function (r, n){
				增加绑定("发送" , r);
				增加绑定("发送错误" , n);
				//r({"data":{}})
			})
		}
		,recallMessage : function(参数){
				console.log("撤回消息recallMessage")
				发送(["撤回消息",参数]);
				return new Promise((function (r, n) {
						增加绑定("撤回消息" , r);
					}))	
		}
		,fetchUserInfoById : function(列表){
				console.log("拉取用户信息fetchUserInfoById")
				发送(["用户信息",列表]);
				return new Promise((function (r, n) {
						增加绑定("用户信息" , r);
					}))	
		}
		,getPresenceStatus : function(列表){
				console.log("查询用户在线状态getPresenceStatus")
				发送(["在线状态",列表]);
				return new Promise((function (r, n) {
						增加绑定("在线状态" , r);
					}))	
		}
		,getJoinedServers : function(参数){
				console.log("获取加入的社区getJoinedServers")
				发送(["社区列表",参数]);
				return new Promise((function (r, n) {
						增加绑定("社区列表" , r);
					}))	
		}
		,getConversationlist : function(参数){
				console.log("获取会话列表getJoinedServers")
				发送(["会话列表",参数]);
				return new Promise((function (r, n) {
						增加绑定("会话列表" , r);
					}))	
		}
		,getContacts : function(参数){
				console.log("获取联系人getContacts")
				发送(["联系人",参数]);
				return new Promise((function (r, n) {
						增加绑定("联系人" , r);
					}))	
		}
		,addContact : function(参数){
				console.log("添加联系人addContact")
				发送(["添加好友",参数]);
				return new Promise((function (r, n) {
						增加绑定("添加好友" , r);
					}))	
		}
		,deleteContact : function(参数){
				console.log("删除好友deleteContact")
				发送(["删除好友",参数]);
		}
		,acceptContactInvite : function(参数){
				console.log("同意添加好友acceptContactInvite")
				发送(["添加好友_同意",参数]);
		}
		,declineContactInvite : function(参数){
				console.log("拒绝添加好友declineContactInvite")
				发送(["添加好友_拒绝",参数]);
		}
		,deleteConversation : function(参数){
				console.log("删除会话deleteConversation")
				发送(["删除会话",参数]);
				return new Promise((function (r, n) {
						增加绑定("删除会话" , r);
					}))	
		}
		,getHistoryMessages : function(参数){
				console.log("获取历史消息getHistoryMessages")
				发送(["历史消息",参数])
				return new Promise((function (r, n) {
						增加绑定("历史消息" , r);
					}))	
		}
		,getServerMembers : function(参数){
				console.log("获取社区成员getServerMembers")
				发送(["社区成员",参数])
				return new Promise((function (r, n) {
						增加绑定("社区成员" , r);
					}))	
		}
		,leaveServer : function(参数){
				console.log("退出社区leaveServer")
				发送(["社区退出",参数])
				return new Promise((function (r, n) {
						增加绑定("社区退出" , r);
					}))	
		}
		,destroyServer : function(参数){
				console.log("退出社区leaveServer")
				发送(["社区解散",参数])
				return new Promise((function (r, n) {
						增加绑定("社区解散" , r);
					}))	
		}
		,createChatThread : function(参数){
				console.log("创建子区createChatThread")
				发送(["子区_创建",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_创建" , r);
					}))	
		}
		,getChatThreadMembers : function(参数){
				console.log("获取子区成员getChatThreadMembers")
				发送(["子区_成员",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_成员" , r);
					}))	
		}
		,leaveChatThread : function(参数){
				console.log("退出子区leaveChatThread")
				发送(["子区_退出",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_退出" , r);
					}))	
		}
		,destroyChatThread : function(参数){
				console.log("删除子区destroyChatThread")
				发送(["子区_删除",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_删除" , r);
					}))	
		}
		,changeChatThreadName : function(参数){
				console.log("子区改名changeChatThreadName")
				发送(["子区_改名",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_改名" , r);
					}))	
		}
		,addReaction : function(参数){
				console.log("添加反应addReaction")
				发送(["反应_添加",参数])
				return new Promise((function (r, n) {
						增加绑定("反应_添加" , r);
					}))	
		}
		,deleteReaction : function(参数){
				console.log("添加反应addReaction")
				发送(["反应_删除",参数])
				return new Promise((function (r, n) {
						增加绑定("反应_删除" , r);
					}))	
		}
		,muteChannelMember : function(参数){
				console.log("频道禁言muteChannelMember")
				发送(["频道禁言",参数])
				return new Promise((function (r, n) {
						增加绑定("频道禁言" , r);
					}))	
		}
		,getServerRole : function(参数){
				console.log("获取社区角色getServerRole")
				发送(["社区角色",参数])
				return new Promise((function (r, n) {
						增加绑定("社区角色" , r);
						//r({"data":{"role":"owner"}})//"owner" | "moderator" | "user"
					}))	
		}
		,getChannelCategory : function(参数){
				console.log("获取频道类别getChannelCategory")
				发送(["频道分组_类别",参数])
				return new Promise((function (r, n) {
						增加绑定("频道分组_类别" , r);
						//r({"data":{"list":["屏道分组id1","屏道分组id2","屏道分组id3"]}})
					}))	
		}
		,deleteCategory : function(参数){
				console.log("删除频道类别deleteCategory")
				发送(["频道分组_删除",参数])
				return new Promise((function (r, n) {
						增加绑定("频道分组_删除" , r);
					}))	
		}
		,getServerTags : function(参数){
				console.log("获取社区标签getServerTags")
				发送(["社区标签",参数])
				return new Promise((function (r, n) {
						增加绑定("社区标签" , r);
					}))	
		}
		,addServerTags : function(参数){
				console.log("增加社区标签addServerTags")
				发送(["社区标签_增加",参数])
				return new Promise((function (r, n) {
						增加绑定("社区标签_增加" , r);
					}))	
		}
		,removeServerTags : function(参数){
				console.log("删减社区标签addServerTags")
				发送(["社区标签_删减",参数])
				return new Promise((function (r, n) {
						增加绑定("社区标签_删减" , r);
					}))	
		}
		,getCategorylist : function(参数){
				console.log("获取频道分组列表getCategorylist")
				发送(["频道分组",参数])
				return new Promise((function (r, n) {
						增加绑定("频道分组" , r);
					}))	
		}
		,getChannelDetail : function(参数){
				发送(["频道详细",参数])
				console.log("获取屏道详细getChannelDetail")
				return new Promise((function (r, n) {
						增加绑定("频道详细" , r);
						return;
					}))	
		}
		,isInChannel : function(参数){
				console.log("是否在频道中isInChannel")
				发送(["频道中",参数])
				return new Promise((function (r, n) {
						增加绑定("频道中" , r);
						//r({"data":{"result":true}})
					}))	
		}
		,getServerIdUnread : function(参数){
				console.log("社区所有频道的未读数getServerIdUnread")
				发送(["社区未读",参数])
				return new Promise((function (r, n) {
						增加绑定("社区未读" , r);
					}))	
		}
		,getServerRecommend : function(){
				console.log("社区推荐表getServerRecommend")
				发送(["社区推荐"])
				return new Promise((function (r, n) {
						增加绑定("社区推荐" , r);
					}))	
		}
		,getServers : function(参数){
				console.log("搜索社区getServers")
				发送(["社区搜索",参数])
				return new Promise((function (r, n) {
						增加绑定("社区搜索" , r);
					}))	
		}
		,joinChannel : function(参数){
				console.log("进入频道joinChannel")
				发送(["进入频道",参数])
				return new Promise((function (r, n) {
						增加绑定("进入频道" , r);
					}))	
		}
		,createCategory : function(参数){
				console.log("创建分组createCategory")
				发送(["频道分组_创建",参数])
				return new Promise((function (r, n) {
						增加绑定("频道分组_创建" , r);
					}))	
		}
		,transferChannel : function(参数){
				console.log("移动分组transferChannel")
				发送(["频道分组_移动",参数])
				return new Promise((function (r, n) {
						增加绑定("频道分组_移动" , r);
					}))	
		}
		,updateCategory : function(参数){
				console.log("更新分组updateCategory")
				发送(["频道分组_更新",参数])
				return new Promise((function (r, n) {
						增加绑定("频道分组_更新" , r);
					}))	
		}
		,createChannel : function(参数){
				console.log("创建频道createChannel")
				发送(["创建频道",参数])
				return new Promise((function (r, n) {
						增加绑定("创建频道" , r);
					}))	
		}
		,destroyChannel : function(参数){
				console.log("删除频道destroyChannel")
				发送(["删除频道",参数])
				return new Promise((function (r, n) {
						增加绑定("删除频道" , r);
					}))	
		}
		,updateChannel : function(参数){
				console.log("更新频道updateChannel")
				发送(["频道更新",参数])
				return new Promise((function (r, n) {
						增加绑定("频道更新" , r);
					}))	
		}
		,getCategoryPublicChannels : function(参数){
				console.log("获取公开屏道列表getCategoryPublicChannels")
				发送(["公开频道",参数])
				return new Promise((function (r, n) {
						增加绑定("公开频道" , r);
						return;
					}))	
		}
		,getCategoryPrivateChannels : function(参数){
				console.log("获取私有频道列表getCategoryPrivateChannels")
				发送(["私有频道",参数])
				return new Promise((function (r, n) {
						增加绑定("私有频道" , r);
						//r({"data":{"list":[]}})
					}))	
		}
		,getChatRoomAttributes : function(参数){
				console.log("获取聊天室全部的自定义属性getChatRoomAttributes")
				//这里跟官方文档有差异,这里是获取用户的id,而不是获取自定义属性
				发送(["房间属性",参数])
				return new Promise((function (r, n) {
						增加绑定("房间属性" , r);
					}))	
		}
		,joinChatThread : function(参数){
				console.log("进入子区对话joinChatThread")
				发送(["子区_进入",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_进入" , r);
					}))	
		}
		,getChatThreadLastMessage : function(参数){
				console.log("子区最新消息getChatThreadLastMessage")
				发送(["子区_最新",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_最新" , r);
					}))	
		}
		,getChatThreadDetail : function(参数){
				console.log("获取子区详细信息getChatThreadDetail")
				发送(["子区_详细",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_详细" , r);
					}))	
		}
		,getChatThreads : function(参数){
				console.log("获取子区列表getChatThreads")
				发送(["子区_列表",参数])
				return new Promise((function (r, n) {
						增加绑定("子区_列表" , r);
						return
					}))	
		}
		,getChannelMutelist : function(参数){
				console.log("查询指定频道的禁言列表getChannelMutelist")
				发送(["禁言列表",参数])
				return new Promise((function (r, n) {
						增加绑定("禁言列表" , r);
					}))	
		}
		,getChannelMembers : function(参数){
				console.log("查询指定频道成员getChannelMembers")
				发送(["频道成员",参数])
				return new Promise((function (r, n) {
						增加绑定("频道成员" , r);
						return
					}))	
		}
		,removeChannelMember : function(参数){
				console.log("移除频道成员removeChannelMember")
				发送(["频道成员_移除",参数])
				return new Promise((function (r, n) {
						增加绑定("频道成员_移除" , r);
						return
					}))	
		}
		,isInServer : function(serverId){
				console.log("是否在社区isInServer")
				发送(["社区中",serverId])
				return new Promise((function (r, n) {
						增加绑定("社区中" , r);
					}))	
		}
		,joinServer : function(serverId){
				console.log("joinServer")
				发送(["社区加入",serverId])
				return new Promise((function (r, n) {
						增加绑定("社区加入" , r);
					}))	
		}
		,inviteUserToServer : function(参数){
				console.log("inviteUserToServer")
				发送(["社区邀请",参数])
				return new Promise((function (r, n) {
						增加绑定("社区邀请" , r);
					}))	
		}
		,updateServer : function(参数){
				console.log("更新社区updateServer")
				发送(["社区更新",参数])
				return new Promise((function (r, n) {
						增加绑定("社区更新" , r);
					}))	
		}
		,inviteUserToChannel : function(参数){
				console.log("inviteUserToChannel")
				发送(["频道成员_邀请",参数])
				return new Promise((function (r, n) {
						增加绑定("频道成员_邀请" , r);
					}))	
		}
		,acceptChannelInvite : function(参数){
				console.log("同意频道邀请acceptChannelInvite")
				发送(["频道成员_同意邀请",参数])
				return new Promise((function (r, n) {
						增加绑定("频道成员_同意邀请" , r);
					}))	
		}
		,rejectChannelInvite : function(参数){
				console.log("拒绝频道邀请rejectChannelInvite")
				发送(["频道成员_拒绝邀请",参数])
				return
		}
		,updateUserInfo : function(参数){
				console.info("修改用户信息updateUserInfo" , 参数)
				发送(["修改用户",参数])
				return new Promise((function (r, n) {
						增加绑定("修改用户" , r);
					}))	
		}
		,subscribePresence : function(参数){
				//订阅状态
				发送(["订阅",参数]);
				console.log("订阅状态:" + 参数);
				return true;
		}
		,unsubscribePresence : function(参数){
				//取消订阅状态
				发送(["订阅_取消",参数]);
				console.log("订阅状态:" + 参数);
				return true;
		}
		,createServer:function(参数){
			console.log("创建社区createServer");
			发送(["创建社区",参数])
			return new Promise((function (r, n) {
					增加绑定("创建社区" , r);
					return;
				}))	
			
		}
		,getServerDetail:function(serverId){
			console.log("getServerDetail");
			发送(["社区详细",serverId])
			return new Promise((function (r, n) {
				增加绑定("社区详细" , r);
				return;
			}))

		}
		,getJoinedChatThreads:function(serverId){
			console.log("获取加入子区getJoinedChatThreads");
			发送(["子区_获取加入",serverId])
			return new Promise((function (r, n) {
				增加绑定("子区_获取加入" , r);
				return;
			}))

		}
		,apiUrl:apiUrl
		,orgName:"orgName"
		,appName:"appName"
		,user:"user"
	}
	return obj;
}

exports.message = {
		create:function(参数){
			var t = "";
			for(var i=0;i<arguments.length;i++)
			t += ("<参数"+ i + ">:"+JSON.stringify(arguments[i]));
			console.log("message:create:" + t);
			
			参数.time = +new Date();
			参数.id = (参数.time + "");
			//参数.from = "";
			//WebIM.conn.send(["消息",参数]);
			return 参数;
		}
}

exports.conn = {
	send:function(){

		var t = "";
		for(var i=0;i<arguments.length;i++)
		t += ("<参数"+ i + ">:"+JSON.stringify(arguments[i]));
		console.log("conn:send:" + t);

		return new Promise(function (r, n){
			r({"data":{}})
		})
		
	}
}


WebIM = exports;})()
//if("undefined"!=typeof module)module.exports = WebIM;

export default WebIM;

